<template>
  <PageContent
    :can-fetch-banner="false"
  >
    <SettingsPageHeader>
      {{ pageTitle }}
    </SettingsPageHeader>
    <div class="login-form">
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
        mode="out-in"
      >
        <component
          :is="view"
          :login-error="loginError"
        />
      </transition>
    </div>
    <div>
      <LoginActions />
    </div>
  </PageContent>
</template>

<script>
import { mapActions } from 'pinia';
import { bannerTypes } from '@@/components/Common/Banner';
import { getLoginError } from '@@/utils/LoginUtils';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';
import EnterEmailForm from '@@/components/Login/EnterEmailForm.vue';
import EnterPasswordForm from '@@/components/Login/EnterPasswordForm.vue';
import LinkSentView from '@@/components/Login/LinkSentView.vue';
import LoginViewMixin from '@@/components/Login/LoginViewMixin';

export default {
  name: 'LoginPage',

  mixins: [
    LoginViewMixin,
  ],

  async setup() {
    definePageMeta({ layout: 'guest-or-apply-promo-code' });

    const { query } = useRoute();
    const loginStore = useLoginStore();

    const { error } = await useAsyncData(async () => {
      await loginStore.getPromoCode(query.promo_code);
      loginStore.setCredentials({ password: null });
      loginStore.setLoginView(loginViews.loginEnterEmail);
    });

    if (error.value) {
      throw createError(parseOpenMountainApiError(error.value));
    }

    const loginError = getLoginError(loginStore);

    return { loginError };
  },

  computed: {
    pageTitle() {
      return this.isLoginLinkSentView ? 'Check Your Email' : 'Log In';
    },

    view() {
      if (this.isLoginEnterEmailView) {
        return EnterEmailForm;
      }

      if (this.isLoginEnterPasswordView) {
        return EnterPasswordForm;
      }

      if (this.isLoginLinkSentView) {
        return LinkSentView;
      }

      return null;
    },
  },

  mounted() {
    this.handleMounted();
  },

  methods: {
    ...mapActions(useUserStore, ['getBanner', 'setBanner']),

    handleMounted() {
      if (this.loginError) {
        const banner = {
          is_dismissable: true,
          message: this.loginError,
          type: bannerTypes.error,
        };
        this.setBanner({ banner });
      }
      else {
        this.getBanner({ canGetUserBanner: false });
      }
    },
  },
};
</script>
